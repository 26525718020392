export interface CastellInterface {
  castell: string;
  estat: string;
  punts: number;
  gamma?: number;
}

class ActuacioType {
  static readonly Concurs = new ActuacioType('#EB6C20');
  static readonly DiadaNacionalCatalunya = new ActuacioType('repeating-linear-gradient(45deg, #ff0000 0px,#ff0000 4px,#ffc700 4px,#ffd289 8px)');
  constructor(public readonly color: string) { }
}

class Colles {
  static readonly Capgrossos = new Colles('Capgrossos de Mataró', '#002e60');
  static readonly ManyacsdeParets = new Colles('Manyacs de Parets', '#54397E');
  static readonly CollaVellaXiquetsdeValls = new Colles('Colla Vella de Xiquets de Valls', '#f08080');
  static readonly CollaJovesXiquetsdeValls = new Colles('Colla Joves de Xiquets de Valls', '#ff0000');
  static readonly Escaldats = new Colles('Castellers de Caldes de Montbui', '#1e4c44');
  static readonly Moixiganguers = new Colles('Moixiganguers d\'Igualada', '#a64d79');
  static readonly CollaMicaco = new Colles('Castellers de Badalona', '#fbbe49');
  static readonly XicsdeGranollers = new Colles('Xics de Granollers', '#721b2b');
  static readonly CollaCastelleradelBaixMontseny = new Colles('Colla Castellera del Baix Montseny', '#0f5766');
  static readonly EsperxatsEstany = new Colles('Esperxats de l\'Estany', '#2f9695');
  static readonly Maduixots = new Colles('Castellers de l\'Alt Maresme i la Selva Marítima', '#ff0000');
  static readonly Capgirats = new Colles('Castellers de Castellar del Vallès', '#8a0f11');
  static readonly CastellersNyerros = new Colles('Castellers dels Nyerros de la Plana', '#000000');
  static readonly CastellersdeMontcadaiReixac = new Colles('Castellers de Montcada i Reixac', '#b22222');
  static readonly JoveHospitalet = new Colles('Jove de l\'Hospitalet', '#5e9f99');
  static readonly JoveBarcelona = new Colles('Jove de Barcelona', '#700d17');
  static readonly Guillats = new Colles('Castellers de la Selva', '#f57242');
  static readonly MinyonsSantaCristina = new Colles('Minyons de Santa Cristina d\'Aro', '#86c440');
  static readonly CastellersdeCastelldefels = new Colles('Castellers de Castelldefels', '#fee601');
  static readonly CastellersdeSantFeliu = new Colles('Castellers de Sant Feliu', '#da2c85');
  static readonly CastellersPratDelLlobregat = new Colles('Castellers del Prat de Llobregat', '#9fc5e8');
  static readonly XicotsVilafranca = new Colles('Xicots de Vilafranca', '#ff0000');
  static readonly MargenersGuissona = new Colles('Margeners de Guissona', '#5b0f00');
  static readonly EsquerraEixample = new Colles('Castellers de l\'Esquerra de l\'Eixample', '#741b47');
  static readonly CastellersCornella = new Colles('Castellers de Cornellà', '#b4a7d6');
  static readonly CastellersTerrassa = new Colles('Castellers de Terrassa', '#00b5e2');
  static readonly VailetsGelida = new Colles('Vailets de Gelida', '#9afe2e');
  static readonly Saballuts = new Colles('Castellers de Sabadell', '#38761d');
  static readonly CastellersLleida = new Colles('Castellers de Lleida', '#990033');
  static readonly CastellersRiberal = new Colles('Castellers del Riberal', '#1e8449');
  static readonly CastellersEsplugues = new Colles('Castellers d\'Esplugues', '#0059ff');
  static readonly CastellersRubi = new Colles('Castellers de Rubí', '#000000');
  static readonly MinyonsTerrassa = new Colles('Minyons de Terrassa', '#cea8d2');
  static readonly NoisdelaTorre = new Colles('Nois de la Torre', '#9fc5e8');
  static readonly CastellersViladeGracia = new Colles('Castellers de la Vila de Gràcia', '#003399');
  static readonly CastellersPobleSec = new Colles('Castellers del Poble Sec', '#9fc5e8');
  static readonly CastellersSantVicenc = new Colles('Castellers de Sant Vicenç dels Horts', '#ff8000');
  static readonly CastellersGavarresa = new Colles('Castellers de la Gavarresa', '#000000');
  static readonly CastellersCerdanyola = new Colles('Castellers de Cerdanyola', '#047f03');
  static readonly CastellersViladecans = new Colles('Castellers de Viladecans', '#9afe2e');
  static readonly MatossersMolinsDeRei = new Colles('Matossers de Molins de Rei', '#6b3529');
  static readonly PallagosConflent = new Colles('Pallagos del Conflent', '#990000');
  static readonly VailetsEmporda = new Colles('Vailets d\'Empordà', '#000000');
  static readonly Adroc = new Colles('Castellers de l\'Adroc', '#741b47');
  static readonly LaietansGramenet = new Colles('Laietans de Gramenet', '#38761d');
  static readonly CastellersFigueres = new Colles('Castellers de Figueres', '#784a97');
  static readonly CastellersSantpedor = new Colles('Castellers de Santpedor', '#ffbf00');
  static readonly SalatsSuria = new Colles('Salats de Súria', '#fa8072');
  static readonly CastellersTortosa = new Colles('Castellers de Tortosa', '#990000');
  static readonly MinyonsArboc = new Colles('Minyons de l\'Arboç', '#ff0000');
  static readonly SagalsOsona = new Colles('Sagals d\'Osona', '#ffa500');
  static readonly JoveXiquetsVilafranca = new Colles('Colla Jove Xiquets de Vilafranca', '#9fc5e8');
  static readonly CastellersRoquetes = new Colles('Castellers de les Roquetes', '#808080');
  static readonly CastellersVilafranca = new Colles('Castellers de Vilafranca', '#009c8b');
  static readonly BousBisbal = new Colles('Bous de la Bisbal', '#ff9900');
  static readonly CastellersMediona = new Colles('Castellers de Mediona', '#003399');
  static readonly Gausacs = new Colles('Castellers de Sant Cugat', '#28724f');
  static readonly Bordegassos = new Colles('Bordegassos de Vilanova', '#ffc803');
  static readonly Tirallongues = new Colles('Tirallongues de Manresa', '#4b5769');
  static readonly EncantatsBegues = new Colles('Encantats de Begues', '#38761d');
  static readonly CastellersEsparreguera = new Colles('Castellers d\'Esparreguera', '#990000');
  static readonly CastellersSantaColoma = new Colles('Castellers de Santa Coloma', '#9fc5e8');
  static readonly XerricsOlot = new Colles('Xerrics d\'Olot', '#990000');
  static readonly CastellersSolsona = new Colles('Castellers de Solsona', '#ffff00');
  static readonly CastellersBerga = new Colles('Castellers de Berga', '#0000ff');
  static readonly SagradaFamilia = new Colles('Castellers de la Sagrada Família', '#38761d');
  static readonly TorrairesMontblanc = new Colles('Torraires de Montblanc', '#cc0000');
  static readonly XiquetsAlcover = new Colles('Xiquets d\'Alcover', '#000000');
  constructor(public readonly nom: string, public readonly color?: string) { }
}

class Punts {
  static readonly $2de6 = new Punts('2de6', 200, 175, 7);
  static readonly $Pde5 = new Punts('Pde5', 210, 185, 7);
  static readonly $9de6 = new Punts('9de6', 265, 230, 7);
  static readonly $4de7 = new Punts('4de7', 275, 240, 7);
  static readonly $3de7 = new Punts('3de7', 290, 250, 7);
  static readonly $3de7a = new Punts('3de7a', 360, 330, 7);
  static readonly $4de7a = new Punts('4de7a', 380, 345, 7);
  static readonly $7de7 = new Punts('7de7', 400, 350, 7);
  static readonly $5de7 = new Punts('5de7', 420, 365, 7);
  static readonly $7de7a = new Punts('7de7a', 440, 415, 7);
  static readonly $5de7a = new Punts('5de7a', 450, 425, 7);
  static readonly $3de7ps = new Punts('3de7ps', 465, 435, 7);
  static readonly $9de7 = new Punts('9de7', 575, 500, 7);
  static readonly $2de7 = new Punts('2de7', 605, 525, 8);
  static readonly $4de8 = new Punts('4de8', 635, 550, 8);
  static readonly $Pde6 = new Punts('Pde6', 665, 580, 8);
  static readonly $3de8 = new Punts('3de8', 700, 610, 8);
  static readonly $7de8 = new Punts('7de8', 875, 760, 8);
  static readonly $2de8f = new Punts('2de8f', 920, 800, 8);
  static readonly $Pde7f = new Punts('Pde7f', 960, 835, 8);
  static readonly $5de8 = new Punts('5de8', 1010, 880, 8);
  static readonly $4de8a = new Punts('4de8a', 1060, 965, 8);
  static readonly $3de8a = new Punts('3de8a', 1110, 1005, 8);
  static readonly $7de8a = new Punts('7de8a', 1125, 1025, 8);
  static readonly $5de8a = new Punts('5de8a', 1165, 1055, 8);
  static readonly $4de9f = new Punts('4de9f', 1460, 1270, 9);
  static readonly $3de9f = new Punts('3de9f', 1530, 1335, 9);
  static readonly $9de8 = new Punts('9de8', 1915, 1665, 9);
  static readonly $3de8ps = new Punts('3de8ps', 2010, 1825);
  static readonly $2de9fm = new Punts('2de9fm', 2110, 1835);
  static readonly $Pde8fm = new Punts('Pde8fm', 2210, 1925);
  static readonly $7de9f = new Punts('7de9f', 2320, 2020);
  static readonly $5de9f = new Punts('5de9f', 2400, 2090);
  static readonly $4de9fa = new Punts('4de9a', 2475, 2250);
  static readonly $3de9fa = new Punts('3de9a', 2555, 2315);
  static readonly $4de9sf = new Punts('4de9sf', 3195, 2680);
  static readonly $2de8sf = new Punts('2de8sf', 3300, 2765);
  static readonly $3de10fm = new Punts('3de10fm', 3405, 2775);
  static readonly $4de10fm = new Punts('4de10fm', 3510, 2870);
  static readonly $9de9f = new Punts('9de9f', 3670, 3190);
  static readonly $2de9sm = new Punts('2de9sm', 3705, 2915);
  static readonly $2de10fmp = new Punts('2de10fmp', 3870, 3370);
  static readonly $Pde9fmp = new Punts('Pde9fmp', 4000, 3480);
  static readonly $3de9sf = new Punts('3de9sf', 4130, 3250);
  static readonly $4de10sm = new Punts('4de10sm', 4260, 3350);

  static readonly $2de5 = new Punts('2de5');
  static readonly $3de5 = new Punts('3de5');
  static readonly $4de5 = new Punts('4de5');
  static readonly $5de5 = new Punts('5de5');
  static readonly $Pde4 = new Punts('Pde4');
  static readonly $Pde4cam = new Punts('Pde4cam');
  static readonly $3de6 = new Punts('3de6', 0, 0, 6);
  static readonly $4de6 = new Punts('4de6', 0, 0, 6);
  static readonly $3de6a = new Punts('3de6a', 0, 0, 6);
  static readonly $4de6a = new Punts('4de6a', 0, 0, 6);
  static readonly $5de6 = new Punts('5de6', 0, 0, 6);
  static readonly $Pde4s = new Punts('Pde4s');
  static readonly $5de5n = new Punts('5de5n');
  static readonly $4de5n = new Punts('4de5n');
  static readonly $3de5n = new Punts('3de5n');
  static readonly $4de6n = new Punts('4de6n');
  static readonly $3de6n = new Punts('3de6n');
  static readonly $3de6s = new Punts('3de6s', 0, 0, 6);
  static readonly $7de6 = new Punts('7de6', 0, 0, 6);
  static readonly $Pde5s = new Punts('Pde5s', 0, 0, 7);
  static readonly $Pde4scam = new Punts('Pde4scam');
  static readonly $2de5n = new Punts('2de5n');
  static readonly $Pde4n = new Punts('Pde4n');

  constructor(public readonly nom: string, public readonly d?: number, public readonly c?: number, public readonly gamma?: number) { }

  descarregat(): CastellInterface {
    return { castell: this.nom, estat: '(Descarregat)', punts: this.d ?? 0, gamma: this.gamma };
  }
  carregat(): CastellInterface {
    return { castell: this.nom, estat: '(Carregat)', punts: this.c ?? 0, gamma: this.gamma };
  }
  intentDesmuntat(): CastellInterface {
    return { castell: this.nom, estat: '(Intent Desmuntat)', punts: 0, gamma: this.gamma };
  }
  intent(): CastellInterface {
    return { castell: this.nom, estat: '(Intent)', punts: 0, gamma: this.gamma };
  }
}

export { Punts, ActuacioType, Colles };