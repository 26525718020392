import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

const dl = {
  'Assaig28': 'https://docs.google.com/forms/d/e/1FAIpQLSemUFOOHYBf-cFE8zKdtVXks-E0wjo1xkI2-sGJxBgvmNcCrg/viewform',
  'FormQuotes': '/form/quotes',
}

export default function DynamicLink() {
  const path = window.location.pathname.split('/').pop();
  const to = dl[path];
  const navigate = useNavigate();
  useEffect(() => {
    if (to?.startsWith('http')) {
      window.location.href = to;
    } else {
      navigate(to ?? '/404');
    }
  }, [navigate, to]);
  return <></>;
}