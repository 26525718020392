import React from "react";
import "./Noticia.scss";
import ImatgeAmpliable from "../../../components/ImatgeAmpliable/ImatgeAmpliable";

export default class Noticia extends React.Component<{
  id?: number;
  preview?: boolean;
  data?: any;
}, {
  data: any;
}> {
  constructor(props: any) {
    super(props);
    this.state = {
      data: null
    };
  }

  componentDidMount(): void {
    if (this.props.data) {
      this.setState({
        data: this.props.data
      });
    } else {
      const id = this.props.id ?? Number(window.location.pathname.match(/\d+/));
      this.fetchData(id);
    }
  }

  componentDidUpdate(prevProps: any): void {
    if (this.props.id !== prevProps.id) {
      if (this.props.data) {
        this.setState({
          data: this.props.data
        });
      } else {
        this.fetchData(this.props.id);
      }
    }
  }

  replaceListItems(texto) {
    let textoNormalizado = texto.replace(/(<br\s*\/?>\s*){2,}/g, '\n').replace(/(<br\s*\/?>\s*)/g, '\n');
    let lineas = textoNormalizado.split(/\n/);
    let resultado = '';
    let enLista = false;
    lineas.forEach((linea) => {
      let textoLinea = linea.trim();
      if (textoLinea.startsWith('- ')) {
        if (!enLista) {
          resultado += '<ul>';
          enLista = true;
        }
        resultado += `<li>${this.replaceListItems(textoLinea.substring(2).trim())}</li>`;
      } else {
        if (enLista) {
          resultado += '</ul>';
          enLista = false;
        }
        resultado += textoLinea + '<br>';
      }
    });
    if (enLista) resultado += '</ul>';
    resultado = resultado.replace(/(?:<br>\s*){2,}/g, '<br><br>');
    resultado = resultado.replace(/(<br>\s*){1}$/g, '');
    return resultado;
  }


  fetchData = async (id?: number) => {
    try {
      const response = await fetch(`https://api.castellersdemollet.cat/noticies?id=${id}`);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      this.setState({
        data: data[0]
      });
    } catch (error: any) {
      console.error('Fetch error:', error);
    }
  };

  replaceMarkdownText(texto) {
    const bold = texto.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
    const italic = bold.replace(/\*(?!\*)(.*?)\*/g, '<i>$1</i>');
    const underline = italic.replace(/_(.*?)_/g, '<u>$1</u>');
    const strikethrough = underline.replace(/~~(.*?)~~/g, '<s>$1</s>');
    const hr = strikethrough.replace(/={3,}/g, '<hr>');
    const codeblock = hr.replace(/```(\w+)?<br>([\s\S]+?)<br>```/g, (_, lang, content) =>
      `<span class="code ${lang || ''}">${content.replace(/<br>/g, ' ')}</span>`
    );
    const coloredText = codeblock.replace(/\[!(red|green|blue|yellow)\](.*?)\[!\]/g, '<span class="colored $1">$2</span>');
    const titles = coloredText.replace(/(#{1,5}) (.*?)(?=<br>|$)/g, (match, p1, p2) => {
      return `<h${p1.length + 1}>${p2}</h${p1.length + 1}>`.trim();
    });
    const links = titles.replace(/\((.*?)\)\[(.*?)\]/g, '<a href="$1" target="_blank">$2</a>');
    return links;
  }

  render(): React.ReactNode {
    return (
      <article className={`noticia${this.props.preview ? 'Preview' : ''}`}>
        <picture>
          <img src={this.state.data?.banner?.replace('/home/cskwmqfj/public_html/assets/', 'https://castellersdemollet.cat/assets/') ?? ''} alt="Imatge de Portada" />
          <img src={this.state.data?.banner?.replace('/home/cskwmqfj/public_html/assets/', 'https://castellersdemollet.cat/assets/') ?? ''} alt="Imatge de Portada" />
        </picture>
        <h2>{this.state.data?.title ?? 'Undefined'}</h2>
        <span className="date">
          {
            (this.state.data ? new Date(this.state.data.date) : new Date()).toLocaleDateString('ca-ES', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })
          }
        </span>
        <p className="cos" dangerouslySetInnerHTML={{
          __html: this.replaceMarkdownText(this.replaceListItems(this.state.data?.body ?? ''))
        }} />
        {
          this.props.preview !== true && this.state.data?.imatges && (
            <section className="gallery">
              {
                this.state.data?.imatges?.split('-').map((imatge: string, index: number) => (
                  <ImatgeAmpliable key={index} src={imatge.replace('/home/cskwmqfj/public_html/assets/', 'https://castellersdemollet.cat/assets/')} alt={`Imatge ${index + 1}`} />
                ))
              }
            </section>
          )
        }
        {
          this.props.preview !== true && (
            <section className="share">
              <span>Comparteix aquesta noticia: </span>
              <button className="x" onClick={
                () => {
                  window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(this.state.data?.header.title ?? 'Undefined')}&url=${encodeURIComponent(window.location.href)}`);
                }
              }>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                </svg>
              </button>
              <button className="facebook" onClick={
                () => {
                  window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`);
                }
              }>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                  <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                </svg>
              </button>
              <button className="whatsapp" onClick={
                () => {
                  window.open(`https://wa.me/?text=${encodeURIComponent(this.state.data?.header.title ?? 'Undefined')}: ${encodeURIComponent(window.location.href)}`);
                }
              }>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                  <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z" />
                </svg>
              </button>
            </section>
          )
        }
      </article>
    );
  }
}