import React from "react";
import "./Soci.scss";
import socis from "../SocisList";

export default class Soci extends React.Component<{}, {
  data: any;
}> {
  constructor(props: any) {
    super(props);
    const id = Number(window.location.pathname.match(/\d+/));
    socis.find(soci => soci.id === id) && this.setState({
      data: socis.find(soci => soci.id === id)
    });
    this.state = {
      data: socis.find(soci => soci.id === id)
    };
  }

  render(): React.ReactNode {
    return (
      <div className="soci">
        <h1>{this.state.data.nom}</h1>
      </div>
    );
  }
}