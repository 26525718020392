import React from 'react';
import './Puntuacio.scss';
import { Punts } from '../Historic/points';

const castells = [
  '2de6',
  'Pde5',
  '9de6',
  '4de7',
  '3de7',
  '3de7a',
  '4de7a',
  '7de7',
  '5de7',
  '7de7a',
  '5de7a',
  '3de7ps',
  '9de7',
  '2de7'
]

enum MBase {
  $P = 3,
  $2 = 1,
  $3 = 0.2,
  $4 = 0,
  $5 = 0.8,
  $7 = 0.1,
  $9 = 0.6,
}

enum MPinya {
  F = 1,
  FA = 1,
  FM = 1,
  SM = 1,
  FMP = 1,
}

enum MTecnic {
  PS = 3,
  A = 1.2,
  SF = 1,
  F = 1,
  FA = 1,
  FM = 1,
  SM = 1,
  FMP = 1,
}

export default class Puntuacio extends React.Component {
  render() {
    const puntuacions = castells.reduce((acc, castell) => {
      const regex = /^(P|\d+)de(\d+)([a-zA-Z]*)$/;
      const match = castell.match(regex);
      const X = !isNaN(parseInt(match[1])) ? parseInt(match[1]) : 1;
      const Y = parseInt(match[2]);
      const tronc = X * Y;
      const modBase = MBase[`$${match[1]}` as keyof typeof MBase] || 1.0;
      const modPinya = MPinya[match[3].toUpperCase() as keyof typeof MPinya] || 1.0;
      const modTecnic = MTecnic[match[3].toUpperCase() as keyof typeof MTecnic] || 1.0;
      const pinya = (X * 25);
      const valor = (Y * 100) + (tronc * modTecnic) * (tronc * modBase);

      acc = [...acc,
      {
        castell: castell,
        valor: valor,
        pinya: pinya
      }
      ];

      return acc;
    }, []).sort((a, b) => a.valor - b.valor);

    const p = Object.values(Object.getOwnPropertyDescriptors(Punts))
      .filter((p) => p.writable)
      .reduce((acc, p) => {
        acc = [...acc, p.value];
        return acc;
      }, [])
      .filter((p) => p.d > 0);

    console.log(p);

    return (
      <section id='puntuacio'>
        <h1>Puntuació</h1>
        <p>La puntuació dels castells es calcula de la següent manera:</p>
        <ul>
          <li>El valor de cada castell és el nombre de pisos + 1, arrodonit a l'enter més proper.</li>
          <li>El valor de cada castell es multiplica pel modificador corresponent.</li>
          <li>La puntuació final és la suma de tots els valors.</li>
        </ul>
        <table>
          <thead>
            <tr>
              <th>Castell</th>
              <th>Descarregat</th>
              <th>Carregat</th>
            </tr>
          </thead>
          <tbody>
            {
              Object.values(p).map((c) => {
                return (
                  <tr key={c.castell} data-gama={'a'}>
                    <td>{c.nom}</td>
                    <td className="puntuacio">{c.d}</td>
                    <td className="puntuacio">{c.c}</td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </section>
    );
  }
}