type Email = `${string}@${string}.${string}`;

interface Soci {
	id: number;
	usuari: string;
	nom: string[];
	cognoms: string[];
	telefon: number;
	email: Email;
	naixement: Date;
	malnom: string;
}

const socis: Soci[] = [
	{
		id: 1,
		usuari: 'aaron',
		nom: ['Aarón'],
		cognoms: ['Sánchez', 'Jiménez'],
		telefon: 635377112,
		email: 'aaronsjimenez@gmail.com',
		naixement: new Date('02/12/2005'),
		malnom: 'Juanito'
	},
	{
		id: 2,
		usuari: 'juande',
		nom: ['Juan', 'de Dios'],
		cognoms: ['González', 'García'],
		telefon: 635377112,
		email: 'juande@caste.cat',
		naixement: new Date('02/12/2005'),
		malnom: 'Juande'
	}
]

export default socis;